<template>
    <Modal
        class="wrap"
        v-model="modal"
        width="440"
        :mask-closable="false"
        :transfer="false"
        @on-visible-change="handleVisibleChange">
        <div class="content-wrap">
            <div class="upgrade-title">{{ $t('pages.login.upgrade') }}</div>
            <p class="upgrade-info">{{ $t('pages.login.upgradeTip') }}</p>
            <Form ref="formCreateBiz" class="formCreateBiz" :model="formCreateBiz" :rules="ruleInline">
                <FormItem prop="name">
                    <Input type="text" size="large" v-model="formCreateBiz.name" :placeholder="$t('pages.login.inputEnterpriseName')" />
                </FormItem>
                <FormItem prop="bizID">
                    <Select size="large" v-model="formCreateBiz.bizID" :placeholder="$t('pages.login.selectIndustryType')" transfer>
                        <Option v-for="(item, index) in bizList" :key="index" :value="item.rowid">{{ item.text._ }}</Option>
                    </Select>
                </FormItem>
                <FormItem prop="inviteCode" class="inviteCode">
                    <Input type="text" size="large" v-model="formCreateBiz.inviteCode" :placeholder="$t('pages.login.inputInvitationCode')" />
                    <Tooltip :content="$t('pages.login.contactCustomerService')" placement="left">
                        <Icon type="md-help-circle" class="help pointer" size="24" />
                    </Tooltip>
                </FormItem>
                <FormItem>
                    <Button class="submit" type="primary" :loading="loading" @click="handleSubmit('formCreateBiz')">{{ $t('pages.login.confirmUpgrade') }}</Button>
                </FormItem>
            </Form>
            
        </div>
        <div slot="footer"></div>
    </Modal>
</template>
<script>
    import cookies from 'vue-cookies';

    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            }
        },
        data () {
            return {
                modal: false,
                formCreateBiz: {
                    name: '',
                    bizID: '',
                    inviteCode: ''
                },
                loading: false,
                bizList: []
            }
        },
        computed: {
            ruleInline() {
                return {
                    name: [
                        { required: true, type: 'string', message: this.$t('common.input'), trigger: 'blur' },
                        { type: 'string', max: 32, message: this.$t('common.upTo32Characters'), trigger: 'blur' }
                    ],
                    bizID: [
                        { required: true, type: 'number', message: this.$t('common.select'), trigger: 'blur' }
                    ],
                    inviteCode: [
                        { required: true, type: 'string', message: this.$t('common.input'), trigger: 'blur' },
                        { type: 'string', pattern: /^[0-9a-zA-Z]{6}$/, message: this.$t('pages.login.inputDigitsAndLetters'), trigger: 'blur' }
                    ]
                }
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.$api.get('specifications/business/list').then(({ data }) => {
                        this.bizList = data
                    })
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$refs.formCreateBiz.resetFields()
                this.$emit('cancel')
            },
            handleSubmit(name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        let params = {
                            name: this.formCreateBiz.name,
                            business_id: this.formCreateBiz.bizID,
                            invite_code: this.formCreateBiz.inviteCode
                        }
                        this.loading = true
                        this.$api.post('organizations/upgrad_from_user', params).then(({ rowid }) => {
                            this.$Message.success(this.$t('message.success'))
                            this.$store.dispatch('Logout').then(() => {
                                this.$api.put('auth/access_token', { organization_id: rowid }).then(({ data }) => {
                                    cookies.set('Authorization', data.token)
                                    localStorage.setItem('token',data.token);
                                    localStorage.setItem('username', data.account);
                                    localStorage.setItem('organization_id', rowid);
                                    localStorage.setItem('organization_name', this.formCreateBiz.name)
                                    localStorage.setItem('account', 'company')
                                    this.$router.replace({ name: 'companySpaces' })
                                    this.cancel()
                                }).finally(() => {
                                    this.loading = false
                                })
                            })
                        }).catch(() => {
                            this.loading = false
                        })
                    }
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.content-wrap {
    margin: 0 auto;
    padding-top: 60px;
    width: 341px;
    .upgrade-title {
        font-size: 19px;
        font-weight: 700;
        text-align: center;
        color: #092c4c;
    }
    .upgrade-info {
        margin: 12px 0 18px;
        font-size: 12px;
        font-weight: 400;
        color: #afb4ba;
    }
}
.inviteCode {
    position: relative;
    .help {
        position: absolute;
        top: 50%;
        right: -32px;
        transform: translateY(-50%);
    }
}
.submit {
    width: 100%;
}
</style>