<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="$t('layout.switchAccount')"
        :mask-closable="false"
        footer-hide
        width="410"
        @on-visible-change="handleVisibleChange">
        <ul class="items-wrap">
            <li class="item pointer" v-for="item in organizationList" :key="item.rowid" @click="handleCompany(item.organization_id)">
                <img :src="`/cym/${item.avatar_uri}`">
                <p class="name line-overflow">{{ item.organization_name }}</p>
                <span class="current" v-if="organization_id === item.organization_id">*{{ $t('layout.currentUse') }}</span>
            </li>
        </ul>
        <p class="personal-login"><span class="pointer" @click="handlePerson">{{ $t('pages.login.personalAccountLogin') }}</span></p>
    </Modal>
</template>
<script>
    import cookies from 'vue-cookies';
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            organizationList: {
                default: ()=> {
                    return []
                }
            }
        },
        data () {
            return {
                modal: false,
                isRemember: true
            }
        },
        computed: {
            organization_id() {
                return this.$store.state.organization_id
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            }
        },
        watch: {
            show(val) {
                this.modal = val
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$emit('cancel')
            },
            handleCompany(organization_id) {
                if (this.organization_id === organization_id) return
                this.cancel()
                this.$store.dispatch('CLEAR_DATA')
                this.$store.dispatch('RESET_ROUTES')
                localStorage.setItem('account', 'company')
                localStorage.setItem('organization_id', organization_id)
                this.$api.put('auth/access_token', { organization_id: organization_id }).then(({ data }) => {
                    cookies.set('Authorization', data.token)
                    window.localStorage.setItem('token',data.token);
                    window.localStorage.setItem('username', data.account);
                    this.$store.dispatch('GenerateRoutes').then(() => {
                        this.$router.addRoutes(this.$store.getters.addRouters)
                        if (this.$store.state.rolesList.indexOf('content') > -1) {
                            this.$router.replace({ name: 'companySpaces' }).then(() => {
                                location.reload()
                            })
                        } else {
                            this.$router.replace({ name: 'mediaScreens' }).then(() => {
                                location.reload()
                            })
                        }
                    })
                })
            },
            handlePerson() {
                if (!this.is_org) return
                this.cancel()
                this.$store.dispatch('CLEAR_DATA')
                localStorage.removeItem('organization_id')
                localStorage.setItem('account', 'person')
                this.$store.dispatch('RESET_ROUTES')
                this.$api.put('auth/access_token', { organization_id: 0 }).then(({ data }) => {
                    cookies.set('Authorization', data.token)
                    window.localStorage.setItem('token',data.token);
                    window.localStorage.setItem('username', data.account);
                    this.$store.dispatch('GenerateRoutes').then(() => {
                        this.$router.addRoutes(this.$store.getters.addRouters)
                        this.$router.replace({ name: 'personSpaces' }).then(() => {
                            location.reload()
                        })
                    })
                })
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";

.items-wrap {
    overflow-y: auto;
    position: relative;
    padding-right: 12px;
    height: calc(100% - 77px);
    .item {
        position: relative;
        margin-bottom: 15px;
        padding: 16px 0 13px 31px;
        width: 100%;
        height: 95px;
        border-radius: 5px;
        font-size: 30px;
        line-height: 50px;
        &:hover {
            background: #f3f3f3;
        }
        img {
            display: inline-block;
            margin-right: 16px;
            width: 66px;
            height: 66px;
            border-radius: 50%;
            vertical-align: middle;
        }
        .name {
            display: inline-block;
            padding: 23px 0;
            width: 158px;
            height: 66px;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
            font-weight: 400;
            vertical-align: middle;
        }
        .current {
            position: absolute;
            right: 0;
            top: 13px;
            padding-right: 5px;
            font-size: 14px;
            color: @primary-color;
        }
    }
}
.personal-login {
    margin-top: 40px;
    padding: 0 12px 16px 0;
    text-align: right;
    span {
        color: @primary-color;
        font-size: 14px;
        line-height: 18px;
    }
}
</style>